import Footer from "../components/Footer.js";
import ConfirmModal from "../components/ConfirmModal.js";
import PopupModal from "../components/PopupModal.js";
import "../styles/Chatroom.css";
import Picker from "emoji-picker-react";
import { useCallback, useEffect, useRef, useState } from "react";
import {Link} from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import TermsAndCondition from "../components/TermsAndCondition.js";

const Chatroom = () =>{

    const specialChatMessages = {
        'clientLeft': "您已離開聊天室",
        'volunLeft': "義工已離開聊天室",
        'clientId': "義工已開啟聊天室"
    }

    const recordFormUrl = "https://sprw.io/stt-f768f9";

    const queueRef = firebase.database().ref('chat_queue');
    const assignedRef = firebase.database().ref('chat_assigned');
    const disconnectRef = firebase.database().ref('disconnect_time');
    const typingRef = firebase.database().ref('typing_status');

    const messageContainerDiv = useRef(null);
    const [currentVolun, setCurrentVolun] = useState(sessionStorage.getItem('heartlinehk-currentVolun'));
    const [chatLog, setChatLog] = useState([]);
    const [isInQueue, setIsInQueue] = useState(false);
    const [isDisconnected, setIsDisconnected] = useState(false);
    const [isEnqueuing, setIsEnqueuing] = useState(false);
    const [isDequeuing, setIsDequeuing] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isEndingChat, setIsEndingChat] = useState(false);
    const [isVolunTyping, setIsVolunTyping] = useState(false);
    const [isUserTyping, setIsUserTyping] = useState(false);
    const [isPickerOpened, setIsPickerOpened] = useState(false);
    const [isTandCRead, setIsTandCRead] = useState(false);
    
    //Callback for handling new chat messages
    const handleChatLogChanges = (snapshot)=>{
        let tmpChatLog = [];
        console.log(snapshot.val());
        if (snapshot.val() != null){
            for (const chatId in snapshot.val()) tmpChatLog.push({
                'chatId': chatId,
                'uid': snapshot.val()[chatId]['uid'],
                'time': snapshot.val()[chatId]['time'],
                'msg': snapshot.val()[chatId]['msg'],
                'spc': snapshot.val()[chatId]['spc']
            });
        }
        console.log(tmpChatLog);
        setChatLog(tmpChatLog);
    };

    //Callback for handling room assigned changes
    const handleRoomAssignedChanges = (snapshot)=>{
        let currentUser = firebase.auth().currentUser;
        if (snapshot.val() != null){
            if (snapshot.val() == "volunLeft"){
                //End Chat if volunteer has left
                console.log("Volunteer Left!");
                setIsInQueue(false);
                endChat();
            }else{
                console.log("Room Assigned!");
                //Remove queue entry when room is assigned
                queueRef.child(currentUser.uid).remove();
                queueRef.child(currentUser.uid).onDisconnect().cancel();
                //Subscribe to chatroom changes
                setupChatroomListener(true);
                setIsInQueue(false);
            }
        }
    };

    //Callback for handling disconnect/reconnect changes
    const handleConnectionChanges = (snapshot)=>{
        let currentUser = firebase.auth().currentUser;
        if (currentUser){
            if (snapshot.val() === true){
                setIsDisconnected(false);
                if (disconnectRef){
                    disconnectRef.child(currentUser.uid).remove();
                    disconnectRef.child(currentUser.uid).onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
                    console.log('OnDisconnect listener is set!');
                }else console.error('ERROR: Disconnect Reference is not available!');
            }else{
                setIsDisconnected(true);
                console.log("Warning: Client is now disconnected!");
            }
        }else console.error('ERROR: Current User is null!');
    };

    //Callback for handling typing status changes
    const handleTypingStatusChanges = (snapshot)=>{
        console.log(snapshot.val());
        setIsVolunTyping(snapshot.val());
    }

    const setupChatroomListener = async (fromRoomAssignedHandler=false)=>{
        try{
            let currentUser = firebase.auth().currentUser;
            //Check if the required database reference is available
            if (!assignedRef) throw new ReferenceError("Room Assigned reference not available!");
            else if (!typingRef) throw new ReferenceError("Typing Stattus reference not available!");
            //Check if the current user is null
            else if (currentUser === null) throw new Error("CurrentUser is null!");
            else{
                //Check if a volunteer is assigned to the current user (i.e. having an ongoing chat)
                let assignedSnapshot = await assignedRef.child(currentUser.uid).once('value');
                let assignedVolun = assignedSnapshot.val();
                if (assignedVolun === null) throw new Error(`No volunteer is assigned to user ${currentUser.uid}!`);
                else if (assignedVolun === "volunLeft"){
                    sessionStorage.removeItem('heartlinehk-currentVolun');
                    await assignedRef.child(currentUser.uid).remove();
                    alert("Previous chat was ended by volunteer!");
                    throw new Error("Previous chat was ended by volunteer!");
                }else{
                    //Unsubscribe to previous chatroom listener if previous assigned volunteer exists
                    let previousAssignedVolun = sessionStorage.getItem('heartlinehk-currentVolun');
                    if (previousAssignedVolun != null){
                        firebase.database().ref(`chat_log/${previousAssignedVolun}`).off('value');
                        typingRef.child(previousAssignedVolun).off('value');
                    }

                    //Subscribe to the chatroom listener
                    firebase.database().ref(`chat_log/${assignedVolun}`).on('value', handleChatLogChanges);

                    //Subscribe to room assigned listener
                    if (!fromRoomAssignedHandler) assignedRef.child(currentUser.uid).on('value', handleRoomAssignedChanges);

                    //Subscribe to connection listener
                    firebase.database().ref('.info/connected').on('value', handleConnectionChanges);

                    //Subscribe to volunteer's typing status listener
                    typingRef.child(assignedVolun).on('value', handleTypingStatusChanges);

                    //Set the current volunteer
                    sessionStorage.setItem('heartlinehk-currentVolun', assignedVolun);
                    console.log("Subscribed to chatroom listener!");
                    setCurrentVolun(assignedVolun);
                    setIsTandCRead(true);
                }
            }
        }catch(error){
            console.error("ERROR: "+error.message);
        }
    };

    const enqueueChat = async (e) =>{
        try{
            //Check if the user is already euqueuing
            if (isEnqueuing) throw new Error("Already enqueuing!");
            setIsEnqueuing(true);

            let currentUser = firebase.auth().currentUser;
            let localCurrentVolun = (currentVolun?currentVolun:sessionStorage.getItem('heartlinehk-currentVolun'));

            //Check if all database references are available
            if (!queueRef){
                setIsEnqueuing(false);
                throw new ReferenceError("Chat Queue reference not available!");
            }else if (!assignedRef){
                setIsEnqueuing(false);
                throw new ReferenceError("Room Assigned reference not available!");
            }
            //Check if current volunteer is already set
            else if (localCurrentVolun != null){
                setIsEnqueuing(false);
                throw new Error("CurrentVolun is already set!");
            }
            //Check if current user is null
            else if (currentUser === null){
                setIsEnqueuing(false);
                throw new Error("CurrentUser is null!");
            }else{
                //Check if the user is in chat queue
                let localIsInQueue = (await queueRef.child(currentUser.uid).once('value')).val();
                if (localIsInQueue != null){
                    setIsDequeuing(false);
                    throw new Error("Already in Chat Queue!");
                }

                //Enqueue to Chat Queue
                let enqueueTransaction = await queueRef.child(currentUser.uid).transaction((queueClient)=>{
                    if (queueClient != null){
                        console.error("ERROR: Queue Client is not Null!");
                        return;
                    }else{
                        return({
                            'status': "inQueue",
                            'time': firebase.database.ServerValue.TIMESTAMP
                        });
                    }
                });
                if (enqueueTransaction.error){
                    setIsEnqueuing(false);
                    throw new Error(enqueueTransaction.error);
                }else if (!enqueueTransaction.committed){
                    setIsEnqueuing(false);
                    throw new Error("Enqueue Transaction Aborted!");
                }
                setIsInQueue(true);

                //Dequeue when client disconnects
                await queueRef.child(currentUser.uid).onDisconnect().remove();

                //Subscribe to listener of the room assigned changes
                assignedRef.child(currentUser.uid).on('value', handleRoomAssignedChanges);

                //End of procedure of enqueuing
                setIsEnqueuing(false);
            }
        }catch(error){
            console.error("ERROR: "+error.message);
        }
    };

    const dequeueChat = async (e) =>{
        try{
            //Check if the user is already dequeuing
            if (isDequeuing) throw new Error("Already dequeuing!");
            setIsDequeuing(true);

            let currentUser = firebase.auth().currentUser;
            let localCurrentVolun = (currentVolun?currentVolun:sessionStorage.getItem('heartlinehk-currentVolun'));


            //Check if all database references are available
            if (!queueRef){
                setIsDequeuing(false);
                throw new ReferenceError("Chat Queue reference not available!");
            }else if (!assignedRef){
                setIsDequeuing(false);
                throw new ReferenceError("Room Assigned reference not available!");
            }
            //Check if a chat is ongoing
            else if (localCurrentVolun != null){
                setIsDequeuing(false);
                throw new Error("CurrentVolun is already set!");
            }
            //Check if currentUser is null
            else if (currentUser === null){
                setIsDequeuing(false);
                throw new Error("CurrentUser is null!");
            }else{
                //Check if the user is in chat queue
                let localIsInQueue = (await queueRef.child(currentUser.uid).once('value')).val();
                if (localIsInQueue === null){
                    setIsDequeuing(false);
                    throw new Error("Not in Chat Queue!");
                }

                //Dequeue at Chat Queue
                await queueRef.child(currentUser.uid).remove();
                await queueRef.child(currentUser.uid).onDisconnect().cancel();

                //Unsubscribe to listener of room assigned changes
                assignedRef.child(currentUser.uid).off('value');
                
                //Reset the in-queue status
                setIsInQueue(false);

                //End of procedure of dequeuing
                setIsDequeuing(false);
            }
        }catch(error){
            console.error("ERROR: "+error.message);

        }
    };

    const endChat = async (e)=>{
        try{
            //Check if a chat is already ending
            if (isEndingChat) throw new Error("A chat is already ending!");
            setIsEndingChat(true);

            let currentUser = firebase.auth().currentUser;
            let localCurrentVolun = (currentVolun?currentVolun:sessionStorage.getItem('heartlinehk-currentVolun'));

            //Check if a chat is ongoing
            if (localCurrentVolun === null){
                setIsEndingChat(false);
                throw new Error("CurrentVolun is null!");
            }
            //Check if current user is null
            else if (currentUser === null){
                setIsEndingChat(false);
                throw new Error("CurrentUser is null!");
            }
            //Check if all databse references are available
            else if (!firebase.database().ref(`chat_log/${currentVolun}`)){
                setIsEndingChat(false);
                throw new ReferenceError("Chatroom reference not available!");
            }else if (!assignedRef){
                setIsEndingChat(false);
                throw new ReferenceError("Room Assigned reference not available!");
            }else if (!typingRef){
                setIsEndingChat(false);
                throw new ReferenceError("Typing Staus reference not available!");
            }else{
                //Check if the volunteer has left the chat
                let assignedSnapshot = await assignedRef.child(currentUser.uid).once('value');
                const isVolunLeft = (assignedSnapshot.val() === 'volunLeft');

                if (!isVolunLeft){
                    //If the volunteer hasn't left, send a special message of "clientLeft" to chat
                    let specialMessage = await firebase.database().ref(`chat_log/${localCurrentVolun}`).push();
                    await specialMessage.set({
                        'uid': currentUser.uid,
                        'time': firebase.database.ServerValue.TIMESTAMP,
                        'spc': "clientLeft"
                    });
                }else{
                    //If the volunteer has left, push a special message of "volunLeft" to the local copy of the chat
                    let tmpChatLog = chatLog;
                    console.log(chatLog);
                    console.log(tmpChatLog);
                    tmpChatLog.push({
                        'chatId': 'volundisconnect',
                        'uid': localCurrentVolun,
                        'time': Date.now(),
                        'spc': 'volunLeft'
                    });
                    setChatLog(tmpChatLog);
                }

                //Delete the room assigned
                await assignedRef.child(currentUser.uid).remove();

                //Delete typing staus
                await typingRef.child(currentUser.uid).remove();

                //Unsubscribe to Chatroom changes
                firebase.database().ref(`chat_log/${localCurrentVolun}`).off('value');

                //Unsubscribe to connection listener
                firebase.database().ref('.info/connected').off('value');

                //Unsubscribe to typing sttatus listener
                typingRef.child(localCurrentVolun).off('value');

                //Reset the current volunteer
                sessionStorage.removeItem('heartlinehk-currentVolun');
                setCurrentVolun(null);

                //Delete anonymous user
                firebase.auth().currentUser.delete();

                //Stop last login timer
                if ("loginTimer" in window) clearInterval(window.loginTimer);

                //Open popup modal for chat record form
                document.getElementById('recordform-modal').classList.add("opened");

                //End of procedure of ending a chat
                setIsEndingChat(false);
            }
        }catch(error){  
            console.error("ERROR: "+error.message);
        }
    };

    const toggleEnqueueDequeue = async ()=>{
        let localCurrentVolun = (currentVolun?currentVolun:sessionStorage.getItem('heartlinehk-currentVolun'));
        if (firebase.auth().currentUser && localCurrentVolun === null){
            if (isInQueue){
                setIsTandCRead(false);
                await dequeueChat();
            }else{
                setIsTandCRead(true);
                await enqueueChat();
            } 
        }
    };

    const sendChatMessage = async (e)=>{
        e.preventDefault();
        try{
            //Check if a message is already sending
            if (isSendingMessage) throw new Error("Already sending a message!");
            else if (isDisconnected) throw new Error("User is disconnected!");
            setIsSendingMessage(true);

            let currentUser = firebase.auth().currentUser;
            let localCurrentVolun = (currentVolun?currentVolun:sessionStorage.getItem('heartlinehk-currentVolun'));

            //Check if a chat is ongoing
            if (localCurrentVolun === null){
                setIsSendingMessage(false);
                throw new Error("Current Volunteer is null!");
            }
            //Check if current user is null
            else if (currentUser === null){
                setIsSendingMessage(false);
                throw new Error("Current User is null!");
            }
            //Check if the message to be sent is empty
            let messageToBeSent = document.getElementById('msg-input').value;
            if (messageToBeSent == null || messageToBeSent == ""){
                setIsSendingMessage(false);
                throw new Error("Message to be sent is empty!");
            }
            else if (!firebase.database().ref(`chat_log/${localCurrentVolun}`)){
                setIsSendingMessage(false);
                throw new ReferenceError("Chatroom reference not available!");
            }else if (!typingRef){
                setIsSendingMessage(false);
                throw new ReferenceError("Typing Status reference not available!");
            }else{
                //Send the message to chatroom
                let newMessageRef = await firebase.database().ref(`chat_log/${localCurrentVolun}`).push();
                let newMessageTransaction = await newMessageRef.transaction((newMessage)=>{
                    if (newMessage === null){
                        return {
                            'uid': currentUser.uid,
                            'time': firebase.database.ServerValue.TIMESTAMP,
                            'msg': messageToBeSent
                        };
                    }else{
                        console.error("ERROR: New Message is not null!");
                        return;
                    }
                });
                if (newMessageTransaction.error){
                    setIsSendingMessage(false);
                    throw new Error(newMessageTransaction.error);
                }else if (!newMessageTransaction.committed){
                    setIsSendingMessage(false);
                    throw new Error("New Message Transaction Aborted!");
                }

                //Rese the typing status
                setIsUserTyping(false);
                await typingRef.child(currentUser.uid).set(false);

                //Clear the message input
                document.getElementById('msg-input').value = "";
                console.log("Message sent!");

                setIsSendingMessage(false);
            }
        }catch (error){
            console.error("ERROR: "+error.message);
        }
    };

    //Function for changing the typing status of current user on database
    const changeTypingStatus = (e)=>{
        if (currentVolun){
            const currentlyTyping = (e.target.value != "");
            if (currentlyTyping != isUserTyping){
                if (typingRef){
                    setIsUserTyping(currentlyTyping);
                    typingRef.child(firebase.auth().currentUser.uid).set(currentlyTyping);
                }else console.error("ERROR: Typing Status reference not available!");
            }
        }
    }

    //Callback for handling the form submission of end chat confirmation modal
    const endChatFormHandler = (e)=>{
        e.preventDefault();
        const modalContainerDiv = e.target.parentElement.parentElement;
        if (modalContainerDiv.id === "endchat-modal"){
            const isConfirmed = (e.target.className === "confirm-btn");
            if (isConfirmed) endChat();
            modalContainerDiv.classList.remove("opened");
        }else console.error("ERROR: Parent Element is not an end chat modal!");
    }

    //Callback for handling selection of emoji in the emoji picker
    const emojiPickerHandler = (e, emojiObject)=>{
        console.log(emojiObject.emoji);
        const msgInput = document.querySelector(".chatroom .chat-container .input-container #msg-input");
        let originalMsgStart = msgInput.value.substring(0, msgInput.selectionStart);
        let originalMsgEnd = msgInput.value.substring(msgInput.selectionEnd, msgInput.value.length);
        if (msgInput.selectionStart === 0 && msgInput.selectionEnd === 0){
            originalMsgStart = msgInput.value;
            originalMsgEnd = "";
        }
        msgInput.value = originalMsgStart + emojiObject.emoji + originalMsgEnd;
        msgInput.focus();
        msgInput.setSelectionRange(originalMsgStart.length+2, originalMsgStart.length+2);

    }

    //Function for toggling the emoji picker
    const toggleEmojiPicker = ()=>{
        if (isPickerOpened) setIsPickerOpened(false);
        else setIsPickerOpened(true);
    }

    const getFormattedDateString = (msec) =>{
        let targetDate = new Date(msec);
        let hourString = (targetDate.getHours()<10?"0"+targetDate.getHours().toString():targetDate.getHours().toString());
        let minuteString = (targetDate.getMinutes()<10?"0"+targetDate.getMinutes().toString():targetDate.getMinutes().toString());
        let monthString = (targetDate.getMonth()<9?"0"+(targetDate.getMonth()+1).toString():(targetDate.getMonth()+1).toString());
        let dayString = (targetDate.getDate()<10?"0"+targetDate.getDate().toString():targetDate.getDate().toString());

        return (hourString+":"+minuteString+", "+dayString+"/"+monthString);
    };


    useEffect(()=>{
        console.log("Chatroom mounted!");
        //Login anonymously
        //Note that if an anonymous user is already logged in, 
        //this signin will return the old anonymous user
        firebase.auth().signInAnonymously()
            .then(async (userCredential)=>{
                const currentUid = firebase.auth().currentUser.uid;
                //Clear any unnecessary items in Web Storage
                let i = 0;
                while (i != localStorage.length){
                    if (localStorage.key(i) != `heartlinehk-${currentUid}`){
                        console.log(localStorage.key(i));
                        localStorage.removeItem(localStorage.key(i));
                    }else i = i+1;
                }
                //If no valid last login time or the last login time is more than 10 minutes ago,
                //the old anonymous user should be deleted
                const lastLoginTime = Number(localStorage.getItem(`heartlinehk-${currentUid}`));
                const currentTime = Date.now();
                if (isNaN(lastLoginTime) || ((currentTime - lastLoginTime) > 600000)){
                    localStorage.removeItem(`heartlinehk-${currentUid}`);
                    await typingRef.child(currentUid).remove();
                    await disconnectRef.child(currentUid).remove();
                    await firebase.auth().currentUser.delete();
                    //Re-signin a new anonymous user
                    await firebase.auth().signInAnonymously();
                }

                //Set Timer to update the last login time every 3 seconds
                if ("loginTimer" in window) clearInterval(window.loginTimer);
                window.loginTimer = setInterval(()=>{
                    if (firebase.auth().currentUser != null){
                        console.log('Changing Last Login Time of '+firebase.auth().currentUser.uid);
                        localStorage.setItem(`heartlinehk-${firebase.auth().currentUser.uid}`, Date.now());
                    }else console.log('Warning: Last Login Time not updated as no user logged in!');
                }, 3000);
                //Setup Chatroom Listener (if the user has unfinished chat)
                setupChatroomListener(false);
            })
            .catch((error)=>{
                console.error("ERROR: "+error.message);
            });

        return ()=>{
            console.log("Chatroom Unmounted!");
            const localCurrentVolun = (currentVolun?currentVolun:sessionStorage.getItem('heartlinehk-currentVolun'));

            //Stop last login timer
            if ("loginTimer" in window) clearInterval(window.loginTimer);

            //Dequeue if user is in queue 
            if (firebase.auth().currentUser != null) dequeueChat();

            //Unsubscribe to chatroom listener and connection listener if user is in chat
            if (firebase.auth().currentUser != null && localCurrentVolun != null){
                firebase.database().ref(`chat_log/${localCurrentVolun}`).off('value');
                firebase.database().ref('.info/connected').off('value');
                typingRef.child(localCurrentVolun).off('value');
            }
        };
    }, []);

    useEffect(()=>{
        //Update the look of connecting messages in Signal style
        if (messageContainerDiv.current){
            let messagesList = messageContainerDiv.current.children;
            for (let i=0; i<messagesList.length; i++){
                if (!messagesList[i].classList.contains("special")){
                    let lastMessage = (i == 0?"None":(messagesList[i-1].classList.contains("left")?"Left":"Right"))
                    let nextMessage = (i == messagesList.length-1?"None":(messagesList[i+1].classList.contains("left")?"Left":"Right"))
                    let currentMessage = (messagesList[i].classList.contains("left")?"Left":"Right")
                    if (lastMessage == currentMessage){
                        if (currentMessage == "Left") messagesList[i].style.borderTopLeftRadius = "0.3rem";
                        else messagesList[i].style.borderTopRightRadius = "0.3rem";
                    }
                    if (nextMessage == currentMessage){
                        if (currentMessage == "Left") messagesList[i].style.borderBottomLeftRadius = "0.3rem";
                        else messagesList[i].style.borderBottomRightRadius = "0.3rem";
                    }
                }
            }
        }
    });

    useEffect(()=>{
        //Scrolling to latest message
        if (chatLog.length > 0){
            messageContainerDiv.current.scrollTo(0, messageContainerDiv.current.scrollHeight);
        }
    }, [chatLog]);

    return (
        <div className="chatroom">
            <div className="main-text">
                <h1>聊天室</h1>
                <p>你好，歡迎進入HeartlineHK聊天室，我們每天由7pm至5am提供服務。<span>我們堅守匿名、保密、不批判、非指導性的四大原則，請放心和我們聊天。</span></p>
            </div>
            {!isTandCRead && <TermsAndCondition onConfirm={toggleEnqueueDequeue}/>}
            {isTandCRead && 
            <div className="chat-container">
                <PopupModal modalId={"recordform-modal"} iframeSrc={recordFormUrl}></PopupModal>
                <ConfirmModal modalId={"endchat-modal"} confirmText={"你確定要結束對話嗎？"} formSubmitHandler={endChatFormHandler}></ConfirmModal>
                {currentVolun && <button type="button" name="endchat-btn" id="endchat-btn" onClick={()=>{document.getElementById("endchat-modal").classList.add("opened")}}><span className="material-icons">cancel</span></button>}
                <div ref={messageContainerDiv} className="messages-container">
                    {isDisconnected &&
                        <div className="loader">
                            <div className="spinning-circle"></div>
                            <p className="connecting-text">重新連線中…</p>
                        </div>
                    }
                    {isInQueue && 
                        <div className="loader">
                            <div className="spinning-circle"></div>
                            <p className="queuing-text">正在等待義工，請不要關閉或刷新視窗</p>
                        </div>
                    }
                    {chatLog.length > 0 && chatLog.map((val, idx)=>{
                        let currentUser = (firebase.auth().currentUser?firebase.auth().currentUser:chatLog[0]['spc']);
                        return(
                            <p key={val['chatId']} className={"message "+(val['spc']?"special":(val['uid'] === currentUser.uid?"right":"left"))}>
                                {(val['msg']?val['msg']:(specialChatMessages[val['spc']]?specialChatMessages[val['spc']]:specialChatMessages['clientId']))}
                                <span>{getFormattedDateString(val['time'])}</span>
                            </p>
                        );
                    })}
                </div>
                <form className="input-container" onSubmit={sendChatMessage}>
                    {isVolunTyping && 
                        <p className="typing-msg">義工正在輸入...</p>
                    }
                    <button type="button" name="emoji-btn" id="emoji-btn" onClick={toggleEmojiPicker}><span className="material-icons">emoji_emotions</span></button>
                    <input type="text" name="msg-input" id="msg-input" placeholder="按此對話…" onInput={changeTypingStatus}  onChange={changeTypingStatus} onPaste={changeTypingStatus} onCut={changeTypingStatus} onSelect={changeTypingStatus}/>
                    <button type="submit" name="submit-btn" id="submit-btn"><span className="material-icons">send</span></button>
                </form>
                {isPickerOpened && <Picker onEmojiClick={emojiPickerHandler}></Picker>}
                <p className="short-description">感謝你的留言，我們會根據留言的先後次序，儘快回覆你。</p>
            </div>
            }
        </div>
    );
}

export default Chatroom;